/* eslint-disable react/prop-types */
import React, { useContext, useState, useEffect } from "react";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { Redirect } from "react-router-dom";

import logo from "./logo_15.png";
import { AuthContext } from "../../contexts/auth.context";
import "./Login.css";

import { getErrorMessage } from "../../lib/firebase-messages-utils";

const useStyles = makeStyles(theme => ({
    message: {
        backgroundColor: theme.palette.error.dark,
        minWidth: "auto",
    },
    submitButton: {
        float: "right",
        height: "36px",
        borderRadius: "2px",
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24)",
        backgroundColor: "var(--dark-seafoam-green)",
        color: "var(--white)",
        "&:hover": {
            backgroundColor: "var(--green)",
            borderColor: "#385cac",
        },
        loadingIndicator: {
            marginRight: theme.spacing(2),
        },
    },
}));

const initialData = {
    email: "",
    password: "",
};

export default function Login(props) {
    const classes = useStyles();
    const { login, logout, error, isAuthenticated, isAuthenticating } =
        useContext(AuthContext);
    const [message, setMessage] = useState(null);
    const [data, setData] = useState(initialData);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onDataChange = key => event => {
        setData({
            ...data,
            [key]: event.target.value,
        });
    };

    const onSubmit = async () => {
        setIsSubmitting(true);
        try {
            await login(data);
        } catch (e) {
            logout();
            console.error("Error loging", e);
            console.error("Error loging", getErrorMessage(e));
            setMessage(getErrorMessage(e));
        } finally {
            setData(initialData);
            setIsSubmitting(false);
        }
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setMessage(null);
    };

    const { from } = props.location.state || {
        from: { pathname: "/" },
    };

    useEffect(() => {
        if (error) setMessage("Ocorreu um erro inesperado, tente novamente");
    }, [error]);

    if (isAuthenticated) {
        return <Redirect to={from} />;
    }

    return (
        <div className="login">
            <div className="login-container">
                <div className="login-logo-container">
                    <div className="login-logo">
                        <img src={logo} alt="logo" height="72px" width="72px" />
                    </div>
                    <div className="login-title">DTAS</div>
                </div>
                <div className="login-form">
                    <div className="login-form-row">
                        <TextField
                            required={true}
                            type="email"
                            name="email"
                            label="Email"
                            disabled={isSubmitting || isAuthenticating}
                            fullWidth
                            value={data.email}
                            onChange={onDataChange("email")}
                        />
                    </div>
                    <div className="login-form-row">
                        <TextField
                            type="password"
                            name="password"
                            label="Senha"
                            autoComplete="current-password"
                            fullWidth
                            disabled={isSubmitting || isAuthenticating}
                            required={true}
                            value={data.password}
                            onChange={onDataChange("password")}
                        />
                    </div>
                    <div className="login-form-actions">
                        <div className="login-password-recover">
                            <a href={"/request-password"}>Esqueci a senha</a>
                        </div>
                        <Button
                            type="submit"
                            variant="contained"
                            onClick={onSubmit}
                            disabled={isSubmitting || isAuthenticating}
                            className={classes.submitButton}
                        >
                            {(isSubmitting || isAuthenticating) && (
                                <CircularProgress
                                    className={classes.loadingIndicator}
                                    size={18}
                                    thickness={2}
                                />
                            )}
                            Entrar
                        </Button>
                    </div>
                </div>
                <Snackbar
                    open={message}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    <SnackbarContent
                        className={classes.message}
                        message={message}
                    />
                </Snackbar>
            </div>
        </div>
    );
}
